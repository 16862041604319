import React from 'react';
import './Styles/Projects.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';


function Projects() {

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const projects = [
        {
            name: 'Ant Escape  ',
            description: (
                <p>
                    Developed a 3D simulation game where players control an ant navigating tunnels,
                    collecting keys, and avoiding obstacles. Designed and coded features for key collection and obstacle avoidance,
                    including visual indicators and game-over conditions. Created and integrated AI-driven ants to challenge the player,
                    utilizing Unity's physics and scripting systems.
                </p>
            ),
            href: 'https://github.com/fati-m/Ant-Escape',
            skills: {
                skill0: "Unity",
                skill1: "C#",
            }
        },
        {
            name: 'Reddit Airline Sentiments  ',
            description: (
                <p>
                    Conducted a sentiment analysis on airline mentions from the "Flights" subreddit using scrape.py to collect hundreds of posts. Analyzed data to determine if sentiments towards airlines were positive, negative, or neutral.
                    Employed linear discriminant analysis to identify recurring topics and common themes such as baggage policies and customer service issues.
                </p>
            ),
            href: 'https://github.com/fati-m/Reddit-Airline-Sentiments',
            skills: {
                skill0: "Python",
                skill1: "Natural Language Processing(NLP)",
                skill2: "Web Scraping",
                skill3: "Text Mining"
            }
        },
        {
            name: 'Personal Portfolio Site  ',
            description: (
                <p>
                    Built a personal protfolio to serve as a platform for my creative endeavors, allowing me
                    to engage with a broader audience. Not only does it reflect my technical/professional
                    journey, but it also encapsulates personal projects and passions that go beyond
                    the confines of a traditional resume.
                </p>
            ),
            href: 'https://fatimahmohammed.com',
            skills: {
                skill0: "React/JavaScript",
                skill1: "CSS",
                skill2: "Node.js"
            }
        },
        {
            name: 'UW-Madison Interactive Map  ',
            description: (
                <p>
                    An interactive user - command line map that loads a graph of
                    some of UW-Madison's buildings from a .gv file and uses kruskal's algorithm to find
                    shortest paths between buildings.
                </p>
            ),
            href: 'https://github.com/fati-m/UW-MadisonInteractiveMap#readme',
            skills: {
                skill0: "Java",
                skill1: "Make",
                skill2: "Git",
                skill3: "JUnit"
            }
        },
        {
            name: 'Interactive Music Database  ',
            description: (
                <p>
                    A user - command line interactive music database of popular songs loaded from a .csv file from kaggle into a red black tree data structure.
                </p>
            ),
            href: 'https://github.com/fati-m/InteractiveMusicDatabase#readme',
            skills: {
                skill0: "Java",
                skill1: "Make",
                skill2: "Git",
                skill3: "JUnit"
            }
        },
        {
            name: 'RedditPostSearchHashtable  ',
            description: (
                <p>
                    A hash table that makes use of a database of reddit posts from kaggle. The hashtable returns reddit posts with their title, url, and body that match key words entered by the user.
                </p>
            ),
            href: 'https://github.com/fati-m/RedditPostSearchHashtable#readme',
            skills: {
                skill0: "Java",
                skill1: "Make",
                skill2: "Git",
            }
        }
    ];

    if (windowWidth <= 1200) {
        return (
            <div className="outer-div" style={{
                width: "200%",
                boxSizing: "border-box"
            }}>
                <div className="Projects section-container" id="projects">
                    {projects.map((project, index) => (
                        <div className="project-card" key={index}>
                            <a href={project.href} target="_blank" rel="noreferrer"
                                style={{ cursor: 'pointer' }}><h3 className='p-name'>{project.name}&nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="p-icon" icon={faArrowUpRightFromSquare} /></h3></a>
                            <div className='p-desc'>{project.description}</div>
                            <div>{project.links}</div>
                            <div className='p-skills'>
                                {Object.values(project.skills).map((skill, skillIndex) => (
                                    <p key={skillIndex} className={`skill-${skillIndex}`}>{skill}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    else {
        return (
            <div>
                <div className="Projects section-container" id="projects">
                    {projects.map((project, index) => (
                        <div key={index}
                            onClick={() => window.open(project.href, "_blank")}
                            className="project-card"
                            style={{ cursor: 'pointer' }}>
                            <h3 className='p-name'>{project.name}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon className="p-icon" icon={faArrowUpRightFromSquare} /></h3>
                            <div className='p-desc'>{project.description}</div>
                            <div>{project.links}</div>
                            <div className='p-skills'>
                                {Object.values(project.skills).map((skill, skillIndex) => (
                                    <p key={skillIndex} className={`skill-${skillIndex}`}>{skill}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Projects;
