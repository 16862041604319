import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    return (
        <div className="Contact contact-container" id="contact">
            <div>
                <a href="https://github.com/fati-m" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="git-icon" size="2xl" style={{ color: "#b3c2e1" }} icon={faGithub} alt="GitHub" />
                </a>
                <a href="https://linkedin.com/in/fatimah-mohammed-97b37b23b" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon className="linked-icon" size="2xl" style={{ color: "#b3c2e1" }} icon={faLinkedin} alt="LinkedIn" />
                </a>
                <a href="mailto:famohammed2@wisc.edu">
                    <FontAwesomeIcon className="mail-icon" size="2xl" style={{ color: "#b3c2e1" }} icon={faEnvelopeSolid} alt="Email" />
                </a>
            </div>
        </div>
    );
}

export default Contact;
