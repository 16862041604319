import React from 'react';
import './Styles/Experience.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faLink, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import ResumePdf from '../MyResume.pdf';
import { useState, useEffect } from 'react';


function Experience() {

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const experiences = [
        {
            company: 'Software Engineering Intern @ Microsoft',
            time: 'MAY 24\' - AUG 24\'',
            description: (
                <p>
                    During my internship at Microsoft, I internationalized the Video Experiences Playback Kit to support multiple languages across Microsoft platforms. I optimized the kit’s UI for both right-to-left and left-to-right orientations, ensuring global usability. Additionally, I integrated the kit into MSN’s infrastructure,
                    enabling seamless multilingual support. I also prototyped and demoed a new feature for Bing, showcasing adaptability and design implementation. My work culminated in a presentation to the Bing Multimedia organization, where the project received high praise.
                </p>
            ),
            href: 'https://www.Microsoft.com',
            skills: {
                skill0: "Typescript",
                skill1: "FAST Framework",
                skill2: "Git",
                skill3: "Azure DevOps",
                skill4: "Scripting",
                skill5: "Figma"
            },
            logo: './logos/MicrosoftLogo.webp',
            companyLogo: 'Microsoft'
        },
        {
            company: 'Software Engineering Consultant @ wesparkle',
            time: 'MAY 23\' - SEPT 23\'',
            description: (
                <p>
                    Currently working as a consultant with wesparkle to build out a customizable chatbot for their member businesses.
                    Built a UI for business owners to choose chatbot customizations for their sites. Created an API to store and retrieve customization choices
                    by business.
                </p>
            ),
            href: 'https://www.wesparkle.co',
            skills: {
                skill0: "React",
                skill1: "CSS",
                skill2: "Google Firebase",
                skill3: "API Creation"
            },
            logo: './logos/weSparkle.png',
            companyLogo: 'We Sparkle'
        },
        {
            company: 'Data Scientist @ Biokind Analytics',
            time: 'JAN 23\' - MAY 24\'',
            description: (
                <p>
                    In spring 23', worked with Babies & Beyond, a Madison, WI based nonprofit offering resources and support for
                    expecting mothers and families with young children. In partnership with Babies & Beyond,
                    my team focused on visualizing various data metrics. Presented our analysis work in May 23'
                    which will help Babies & Beyond target their services and quantify their impact to sponsors and donors.
                </p>
            ),
            href: 'https://www.biokind.org',
            links: (
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    paddingBottom: "10px"
                }}>
                    <a className='e-links'
                        href='https://babiesandbeyondwi.org/'
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}>
                        Babies & Beyond<FontAwesomeIcon icon={faLink} /></a>
                    <a className='e-links'
                        href='https://www.biokind.org/post/uw-madison-spring-2023-teams'
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => e.stopPropagation()}>
                        Spring 23' Team<FontAwesomeIcon icon={faLink} /></a>
                </div>
            ),
            skills: {
                skill0: "R",
                skill1: "R Studio",
                skill2: "Data Cleaning",
                skill3: "Data Analysis",
                skill4: "Microsoft Excel"
            },
            logo: './logos/biokind.png',
            companyLogo: 'Biokind Analytics'
        },
        {
            company: 'Phone Agent @ University of Wisconsin - Madison, Division of Information Technology',
            time: 'OCT 22\' - MAY 24\'',
            description: (
                <p>
                    Working as a technical call representative serving clients in the University of Wisconsin school system primary helping callers
                    with multi-factor authentication, password resets, and software installation.
                </p>
            ),
            href: "https://it.wisc.edu/",
            links: "",
            skills: {
                skill0: "Customer Service",
                skill1: "Technical Troubleshooting"
            },
            logo: './logos/uw-mad.png',
            companyLogo: 'UW Madison'
        },
        {
            company: 'Tech Consultant @ Target',
            time: 'AUG 19\' - MAR 20\'',
            description: (
                <p>Served the tech section at my local Target store while in highschool stocking shelfs,
                    updating sales floor signs, answering tech-product questions,and checking out customers.</p>
            ),
            href: "https://target.com",
            links: "",
            skills: {
                skill0: "Customer Service",
                skill1: "Inventory",
                skill2: "Sales"
            },
            logo: './logos/target.png',
            companyLogo: 'Target'
        }
        // Add more experiences here
    ];


    if (windowWidth <= 1200) {
        return (
            <div className="outer-div" style={{
                width: "200%",
                boxSizing: "border-box"
            }}>
                <div className="Experience section-container" id="experience">
                    {experiences.map((experience, index) => (
                        <div className="experience-card" key={index}>
                            <a href={experience.href} target="_blank" rel="noreferrer" style={{ cursor: 'pointer' }}>
                                {/* <h3 className='e-company' style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <div style={{ alignContent: "center" }}>
                                        <img src={`${experience.logo}`} alt={`${experience.companyLogo} logo`} />
                                    </div>
                                    <div>
                                        {experience.company}&nbsp;&nbsp;&nbsp;
                                        <FontAwesomeIcon className="e-icon" icon={faArrowUpRightFromSquare} />
                                    </div>
                                </h3> */}
                                <h3 className='e-company'>{experience.company}&nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="e-icon" icon={faArrowUpRightFromSquare} />
                                </h3>
                            </a>
                            <div className='e-time'>{experience.time}</div>
                            <div className='e-desc'>{experience.description}</div>
                            <div>{experience.links}</div>
                            <div className='e-skills'>
                                {Object.values(experience.skills).map((skill, skillIndex) => (
                                    <p key={skillIndex} className={`skill-${skillIndex}`}>{skill}</p>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div style={{
                        marginTop: "50px",
                        marginBottom: "50px"
                    }} className='resume'>
                        <a href={ResumePdf} target="_blank" rel="noopener noreferrer">
                            VIEW FULL RESUME&nbsp;&nbsp;&nbsp;<FontAwesomeIcon className="r-icon" icon={faFilePdf} />
                        </a>
                    </div>
                </div>
            </div >
        );
    }
    else {
        return (
            <div>
                <div className="Experience section-container" id="experience">
                    {experiences.map((experience, index) => (
                        <div key={index}
                            onClick={() => window.open(experience.href, "_blank")}
                            className="experience-card"
                            style={{ cursor: 'pointer' }}>
                            <h3 className='e-company' style={{
                                display: 'flex', justifyContent: 'space-between'
                            }}>
                                <div>
                                    {experience.company}&nbsp;&nbsp;&nbsp;
                                    <FontAwesomeIcon className="e-icon" icon={faArrowUpRightFromSquare} />
                                </div>
                                <div style={{ width: "100px", textAlign: 'center' }}>
                                    <img src={`${experience.logo}`} alt={`${experience.companyLogo} logo`} />
                                </div>
                            </h3>
                            <div className='e-time'>{experience.time}</div>
                            <div className='e-desc'>{experience.description}</div>
                            <div>{experience.links}</div>
                            <div className='e-skills'>
                                {Object.values(experience.skills).map((skill, skillIndex) => (
                                    <p key={skillIndex} className={`skill-${skillIndex}`}>{skill}</p>
                                ))}
                            </div>
                        </div>
                    ))
                    }
                    <div style={{
                        marginTop: "50px",
                        marginBottom: "50px"
                    }} className='resume'>
                        <a href={ResumePdf} target="_blank" rel="noopener noreferrer">
                            VIEW FULL RESUME&nbsp;&nbsp;&nbsp;<FontAwesomeIcon className="r-icon" icon={faFilePdf} />
                        </a>
                    </div>
                </div >
            </div >
        );
    }
}

export default Experience;
