import React from 'react';
import './App.css';
import Header from './components/Header';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Sidebar from './components/Sidebar';

function App() {
  return (
    <div className="App">
      <header className='header-section'>
        <div>
          <Header />
        </div>
      </header>
      <section>
      </section>
      <Sidebar />
      <div className='body-section'>
        <div>
          <About />
        </div>
        <div className='s'>
          <h1 className="section-header">EXPERIENCE</h1>
          <Experience />
        </div>
        <div className='s'>
          <h1 className="section-header">PROJECTS</h1>
          <Projects />
        </div>
      </div>
    </div>
  );
}

export default App;
