import React from 'react';
import 'animate.css';
import './Styles/Header.css';
import Contact from './Contact';
import { useState, useEffect } from 'react';

const Header = () => {

    const [, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <header className="header">
            <h1 className='my-name'>Fatimah Mohammed</h1>
            <div style={{
                width: "max-content"
            }}>
                <Contact />
            </div>
        </header>
    );
}

export default Header;
