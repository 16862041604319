import React, { useState, useEffect } from 'react';
import './Styles/Sidebar.css';
import { Link as ScrollLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';



const Sidebar = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isLinkHovered, setIsLinkHovered] = useState(false);
    const [isLinkActive] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    const closeSidebar = () => {
        if (windowWidth <= 1200) {
            setIsSidebarOpen(false);
        }
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isSidebarOpen]);


    if (windowWidth <= 1200) {
        return (
            <nav className={`menu ${isSidebarOpen ? 'show' : ''}`}>
                <div className={`menu-toggle ${isSidebarOpen ? 'active' : ''}`} onClick={toggleSidebar}>
                    {isSidebarOpen ? (
                        <FontAwesomeIcon icon={faTimes} />
                    ) : (
                        <FontAwesomeIcon icon={faBars} />
                    )}
                </div>
                <div className={isSidebarOpen ? "nav-links show-nav" : "nav-links"}>
                    <div>
                        <ScrollLink
                            className="menu-link"
                            to="about"
                            smooth={true}
                            spy={true}
                            offset={-150}
                            activeClass='active'
                            onMouseEnter={() => setIsLinkHovered(true)}
                            onMouseLeave={() => setIsLinkHovered(false)}
                            onClick={closeSidebar}
                        >
                            About
                        </ScrollLink>

                        <ScrollLink
                            className="menu-link"
                            to="experience"
                            smooth={true}
                            spy={true}
                            offset={-80}
                            activeClass='active'
                            onMouseEnter={() => setIsLinkHovered(true)}
                            onMouseLeave={() => setIsLinkHovered(false)}
                            onClick={closeSidebar}
                        >
                            Experience
                        </ScrollLink>

                        <ScrollLink
                            className="menu-link"
                            to="projects"
                            smooth={true}
                            spy={true}
                            offset={-80}
                            activeClass='active'
                            onMouseEnter={() => setIsLinkHovered(true)}
                            onMouseLeave={() => setIsLinkHovered(false)}
                            onClick={closeSidebar}
                        >
                            Projects
                        </ScrollLink>
                    </div>
                </div>
            </nav>
        );
    }
    else {
        return (
            <nav className={`menu ${isHovered ? 'show' : ''} ${(isLinkHovered || isLinkActive) ? 'expanded' : ''}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className="menu-icon">
                    <FontAwesomeIcon icon={faBars} />
                </div>
                <div>
                    <ScrollLink
                        className="menu-link"
                        to="about"
                        smooth={true}
                        spy={true}
                        offset={-150}
                        activeClass='active'
                        onMouseEnter={() => setIsLinkHovered(true)}
                        onMouseLeave={() => setIsLinkHovered(false)}
                        onClick={closeSidebar}
                    >
                        About
                    </ScrollLink>

                    <ScrollLink
                        className="menu-link"
                        to="experience"
                        smooth={true}
                        spy={true}
                        offset={-100}
                        activeClass='active'
                        onMouseEnter={() => setIsLinkHovered(true)}
                        onMouseLeave={() => setIsLinkHovered(false)}
                        onClick={closeSidebar}
                    >
                        Experience
                    </ScrollLink>

                    <ScrollLink
                        className="menu-link"
                        to="projects"
                        smooth={true}
                        spy={true}
                        offset={-100}
                        activeClass='active'
                        onMouseEnter={() => setIsLinkHovered(true)}
                        onMouseLeave={() => setIsLinkHovered(false)}
                        onClick={closeSidebar}
                    >
                        Projects
                    </ScrollLink>
                </div>
            </nav>
        );
    }
};

export default Sidebar;
